/* eslint-env node */

module.exports = {
  CATCH_ALL_LOCALE: 'catchAll',
  DEFAULT_CONFIG_KEY: 'default',
  GTM_PAGE_VIEW_EVENT_NAME: '_pageview',
  GA_ID_COOKIE_NAME: '_ga',
  SESSION_COOKIE_NAME: 'sid',
  HOME_ROUTE: '/',
  NEXT_LOCALE_COOKIE_NAME: 'NEXT_LOCALE',
  PAGE_ALWAYS_REQUIRED_NAMESPACES: [
    'navbar',
    'footer',
    'cookieBar',
    'common',
    'encouragementSection',
  ],
  REVALIDATE_WEBINARS_TIME_IN_SECONDS: 60 * 60 * 24,
  REVALIDATE_ZAUFANE_TIME_IN_SECONDS: 60 * 60,
  REVALIDATE_ZAPIER_TIME_IN_SECONDS: 60 * 60 * 24,
  REVALIDATE_INTEGRATIONS_TIME_IN_SECODS: 60 * 10,
  DEFAULT_COUNTRY_WHEN_NO_GEOIP_AVAILABLE: 'BE',
  REVALIDATE_BLOG_POSTS_TIME_IN_SECONDS: 60 * 60 * 24,
  COUNTRY_CODE_COOKIE: 'countryCode',
  GA_USER_ID_COOKIE_YEARS_TO_EXPIRE: 10,
  DEFAULT_LOCALE: 'EN',
  LOCALES_OF_WHICH_CHARACTERS_DONT_WORK_IN_KAYAKO: ['el'],
};
