import Cookie from 'js-cookie';
import debounce from 'lodash/debounce';

import { GTM_PAGE_VIEW_EVENT_NAME, GA_ID_COOKIE_NAME } from '../../constants';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    gtmDidInit?: boolean;
  }
}

const getId = () =>
  String(Date.now().toString(32) + Math.random().toString(16)).replace('.', '');

const getCid = (): string | null => {
  return Cookie.get(GA_ID_COOKIE_NAME) ?? null;
};

export const initGTM = (host: string, trackingId: string): void => {
  if (window.gtmDidInit) {
    return;
  }

  window.gtmDidInit = true;

  const script = document.createElement('script');

  window.dataLayer = window.dataLayer || [];

  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => {
    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });
  };
  script.src = `${host}/gtm.js?id=${trackingId}`;

  document.head.appendChild(script);

  const cookieBotCookie = Cookie.get('CookieConsent');

  if (!cookieBotCookie) {
    window.addEventListener('CookiebotOnConsentReady', () => {
      const consent = window.Cookiebot?.consent ?? '';
      const eventName = 'cb_consent_init';

      const params = {
        id: getId(),
        timestamp: Date.now(),
        client_id: getCid(),
        cookiebot_consent: JSON.stringify(consent),
      };

      const headers = {
        type: 'text/plain',
      };

      pushToDataLayer(eventName, params);

      const blob = new Blob(
        [JSON.stringify({ ...params, event_name: eventName })],
        headers,
      );
      navigator.sendBeacon('https://sgtm.smsapi.pl/consentInit', blob);
    });
  }
};

export const pushToDataLayer = <T = any>(
  event: string,
  variables?: T,
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  let dataLayerEvent = {
    event,
    cid: getCid(),
  };

  if (variables) {
    dataLayerEvent = {
      ...dataLayerEvent,
      ...variables,
    };
  }

  window.dataLayer.push(dataLayerEvent);
};

const getLastPageViewEventFromDataLayer = (): Record<
  string,
  unknown
> | null => {
  const { dataLayer } = window;

  if (!dataLayer) {
    return null;
  }

  const [lastPageViewItem] = dataLayer
    .filter(
      (gtmEvent) =>
        gtmEvent.event && gtmEvent.event === GTM_PAGE_VIEW_EVENT_NAME,
    )
    .reverse();

  return lastPageViewItem;
};

const DELAY_WHICH_IS_LONGER_THEN_TIME_BETWEEN_HANDLE_ROUTE_CHANGE_EVENT_AND_COOKIEBOT_ON_ACCEPT_COOKIES_EVENT_IN_MS = 3000;

export const pushPageViewToDataLayer = debounce(
  () => {
    if (!Cookie.get('CookieConsent')) {
      return;
    }

    if (getLastPageViewEventFromDataLayer()?.pageTitle === document.title) {
      return;
    }

    pushToDataLayer(GTM_PAGE_VIEW_EVENT_NAME, {
      pageTitle: document.title,
    });
  },
  DELAY_WHICH_IS_LONGER_THEN_TIME_BETWEEN_HANDLE_ROUTE_CHANGE_EVENT_AND_COOKIEBOT_ON_ACCEPT_COOKIES_EVENT_IN_MS,
  {
    leading: true,
    trailing: false,
  },
);

export const initGTMOnEvent =
  (host: string, trackingId: string) =>
  (event: MouseEvent | TouchEvent | DocumentEventMap['scroll']): void => {
    initGTM(host, trackingId);

    if (event.currentTarget) {
      event.currentTarget.removeEventListener(
        event.type,
        initGTMOnEvent(host, trackingId),
      );
    }
  };

export const setGTMListeners = (host: string, trackingId: string): void => {
  document.addEventListener('scroll', initGTMOnEvent(host, trackingId));
  document.addEventListener('mousemove', initGTMOnEvent(host, trackingId));
  document.addEventListener('touchstart', initGTMOnEvent(host, trackingId));
};
